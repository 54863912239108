import { Picture } from '@components/ui'
import styles from './index.module.css'
import cn from 'classnames'
import {
  MULPASS_URL,
  NEXT_PUBLIC_GOOGLE_AUTH_CLIENT_ID,
  NEXT_PUBLIC_FACEBOOK_APP_ID,
} from '@shopify/const'
import { parse } from 'query-string'
import { useCallback, useMemo } from 'react'
import { useRouter } from 'next/router'
import FacebookLogin from '../../libs/ReactFacebookLogin'
import registrationsTrack from '../../RegistrationsTrack'
import { getAppName } from '../../const'

export function getGoogleAuthURL({ location, app }) {
  return `https://accounts.google.com/o/oauth2/auth?${Object.entries({
    redirect_uri: encodeURIComponent(`${MULPASS_URL}/auth/google/callback`),
    response_type: 'code',
    scope: 'email',
    client_id: NEXT_PUBLIC_GOOGLE_AUTH_CLIENT_ID,
    state: encodeURIComponent(
      JSON.stringify({
        app,
        ...parse(location.search.slice(1)),
        callback: `${MULPASS_URL}/auth/google/callback`,
      })
    ),
    nonce: Date.now(),
  })
    .map(([key, value]) => `${key}=${value}`)
    .join('&')}`
}

const RegistrationsThirdLogin = ({ data, gs }) => {
  const s = { ...styles, ...gs }
  const { locale } = useRouter()

  const fb_redirect_uri = useMemo(
    () => `${MULPASS_URL}/auth/facebook/callback`,
    []
  )

  const facebookLoginState = useMemo(() => {
    return encodeURIComponent(
      JSON.stringify({
        loginType: 'facebook',
        app: getAppName(locale),
        redirect: location.href,
        callback: fb_redirect_uri,
      })
    )
  }, [locale, fb_redirect_uri])

  const handleGoogleAuth = useCallback(
    (e) => {
      e.preventDefault()
      const googleAuthURL = getGoogleAuthURL({
        location: window.location,
        app: getAppName(locale),
      })
      registrationsTrack.loginWithThirdTrack({
        typeText: 'google',
      })
      window.location.href = googleAuthURL
    },
    [locale]
  )

  const onSuccess = useCallback(
    ({ accessToken }) => {
      if (!accessToken) return
      const redirectUrl = new URL(fb_redirect_uri)
      redirectUrl.searchParams.set('access_token', accessToken)
      redirectUrl.searchParams.set('state', facebookLoginState)
      window.location.href = redirectUrl.toString()
      console.log('success', accessToken, redirectUrl.toString())
      registrationsTrack.loginWithThirdTrack({
        typeText: 'facebook',
      })
    },
    [fb_redirect_uri, facebookLoginState]
  )

  return (
    <div className={s.registrations_third_login}>
      <div className={s.rstl_divider}>
        <span>{data?.divider_text}</span>
      </div>
      <div className={cn(s.rstl_third_wrap, s.flex_center)}>
        {data?.third_button1_icon && (
          <a className={s.rstl_third_item} onClick={handleGoogleAuth}>
            <Picture
              alt={data?.third_button1_icon.alt}
              source={`${data?.third_button1_icon}`}
              className="aspect-h-[42] aspect-w-[42] w-full"
              imgClassName="object-contain"
            />
          </a>
        )}
        {data?.third_button2_icon && (
          <FacebookLogin
            appId={NEXT_PUBLIC_FACEBOOK_APP_ID}
            scope="public_profile,email"
            fields="name,email,picture"
            responseType="token"
            state={facebookLoginState}
            mobileCallback={() =>
              registrationsTrack.loginWithThirdTrack({
                typeText: 'facebook',
              })
            }
            redirectUri={`${MULPASS_URL}/auth/facebook/callback`}
            callback={onSuccess}
            customParams={{}}
            render={(renderProps) => (
              <a
                className={s.rstl_third_item}
                onClick={(...args) => renderProps.onClick(args)}
              >
                <Picture
                  alt={data?.third_button2_icon.alt}
                  source={`${data?.third_button2_icon}`}
                  className="aspect-h-[42] aspect-w-[42] w-full"
                  imgClassName="object-contain"
                />
              </a>
            )}
          />
        )}
      </div>
    </div>
  )
}

export default RegistrationsThirdLogin
